import api from '@/services/api';

const headers = {
  'Content-Type': 'multipart/form-data',
};

/* eslint-disable-next-line import/prefer-default-export */
export const createPhoto = (files) => {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    formData.append('files', file, file.name);
  }

  return api.post('photo', formData, headers);
};

export const createFeaturedPhoto = (file) => {
  const formData = new FormData();
  formData.append('files', file, file.name);

  return api.post('photo/featured-image', formData, headers);
};

/**
 * Service to save photo with custom resolution 364x546
 */
export const createProviderPhoto = (file) => {
  const formData = new FormData();
  formData.append('files', file, file.name);

  return api.post('photo/provider-image', formData, headers);
};
