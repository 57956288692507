import api from '@/services/api';

const url = 'target-product';

const create = (targetProduct) => api.post(`${url}/create-target-product`, targetProduct);

const findAll = () => api.get(`${url}/read-target-product`);

const update = (targetProduct) => api.put(`${url}/update-target-product`, targetProduct);

const deleteOne = (id) => api.delete(`${url}/delete-target-product/${id}`);

export default {
  create, findAll, update, deleteOne,
};
