<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Credenciado</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="provider.name"
            :counter="50"
            outlined
            dense
            label="Nome *"
            :rules="$rules.required"
            required
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="provider.crm"
            :counter="100"
            outlined
            dense
            label="CRM *"
            :rules="$rules.required"
            required
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="provider.city"
            :counter="100"
            outlined
            dense
            label="Cidade *"
            :rules="$rules.required"
            required
          />
        </v-col>

        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="provider.state"
            :counter="50"
            outlined
            dense
            label="Estado *"
            :rules="$rules.required"
            required
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="provider.specialty"
            :counter="100"
            outlined
            dense
            label="Especialidade *"
            :rules="$rules.required"
            required
          />
        </v-col>
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        />
      </v-row>
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="pr-5"
        >
          <v-textarea
            v-model="provider.curriculum"
            :counter="400"
            label="Curriculo *"
            :rules="$rules.required"
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="provider.targetProd"
            :items="targetProducts"
            chips
            label="Produtos Alvos do Credenciado"
            item-text="title"
            item-value="id"
            return-object
            multiple
            outlined
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
        >
          <v-file-input
            v-model="imagesUpload"
            outlined
            :images="imagesPreview"
            label="Clique para adicionar imagens do tamanho de 364x546"
            prepend-icon="mdi-camera"
            :rules="imageAlreadyExists ? []: $rules.required "
            @change="onFilesSelected"
          />
        </v-col>
      </v-row>

      <v-col>
        <gallery
          :images="imagesPreview"
          :rules="$rules.required"
          @image-deleted="onDeleteImage"
        />
      </v-col>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import ProviderService from '../../../services/provider.service';
import TargetProductService from '../../../services/target-product.service';
import { createProviderPhoto } from '../../../services/photos-service';

export default {
  name: 'ProviderForm',
  directives: { mask },
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      provider: {
        name: '',
        crm: '',
        city: '',
        state: '',
        specialty: '',
        curriculum: '',
        targetProd: [],
      },
      isLoading: false,
      targetProducts: [],
      imagesPreview: [],
      imagesUpload: null,
    };
  },
  computed: {
    imageAlreadyExists() {
      return Boolean(this.provider.image) || Boolean(this.imagesUpload);
    },
  },
  async created() {
    if (this.populateWith.id) {
      this.provider = this.populateWith;
      this.imagesPreview = this.provider?.image ? [this.provider?.image] : [];
    }
    this.targetProducts = await TargetProductService.findAll();
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      this.imagesPreview = [URL.createObjectURL(files)];
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      if (this.imagesUpload) {
        this.imagesUpload.splice(index, 1);
      }

      this.provider = { ...this.provider, image: null };
    },

    async submit() {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        if (this.populateWith.id) {
          try {
            if (this.imagesUpload) {
              const { link } = await createProviderPhoto(this.imagesUpload);
              const providerToUpdate = {
                ...this.provider, image: link,
              };
              await ProviderService.updateProvider(providerToUpdate);
              this.$emit('provider-edited', this.provider);
              this.isLoading = false;
              this.$toast.success('Credenciado cadastrado com sucesso');
            } else {
              const providerToUpdate = {
                ...this.provider,
              };
              await ProviderService.updateProvider(providerToUpdate);
              this.$emit('provider-edited', this.provider);
              this.isLoading = false;
              this.$toast.success('Credenciado cadastrado com sucesso');
              this.close();
            }
          } catch (e) {
            this.isLoading = false;
            this.$handleHttpError(e);
          }
        } else {
          try {
            const { link } = await createProviderPhoto(this.imagesUpload);
            const providerToSave = {
              ...this.provider, image: link,
            };
            await ProviderService.createProvider(providerToSave);
            this.$emit('provider-added', { ...this.provider });
            this.$refs.form.reset();
            this.provider.text = null;
            this.imagesPreview = [];
            this.$refs.form.resetValidation();
            this.isLoading = false;
            this.$toast.success('Credenciado cadastrado com sucesso');
            this.close();
          } catch (e) {
            this.isLoading = false;
            this.$handleHttpError(e);
          }
        }
      } else {
        this.isLoading = false;
        this.$toast.error('Verifique os campos e tente novamente');
      }
    },
  },
};
</script>
